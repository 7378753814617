import fetch from 'lib/decorated-fetch'
import getUrl from 'lib/url'
import propsFilter, { IFilterConfig } from 'lib/props-filter'
import serializeObject from '@arsenal/arsenal/modules/serializeObject'

export interface ICommonVideoInfo {
  cover_url: string
  video_id: string
  video_watch_count: number
  video_type: number
  video_duration: number
  video_size: string
}

export interface ICommonUserInfo {
  avatar_url: string
  name: string
  description: string
  user_id: 4661945771
  user_verified: boolean
  verified_content: string
  motor_auth_show_info: {
    auth_v_type: number
    auth_v_desc: string
  }
}

export interface ICommonColumn {
  id: 225
  uid: number
  mid: number
  logo: string
  name: string
  description: string
  modify_time: number
  create_time: number
  episode: number
  introduction: string
}

export interface ICommonVideoData {
  unique_id_str: string
  title: string
  publish_time: number
  article_type: number
  video_info: ICommonVideoInfo
  column: ICommonColumn
}

const originFilterConfig: IFilterConfig = {
  type: 'object',
  props: {
    column_tab: {
      type: 'array',
      props: {
        column_id: '',
        name: '',
      },
    },
    video_list: {
      type: 'array',
      props: {
        article_type: '',
        publish_time: '',
        title: '',
        unique_id_str: '',
        video_info: {
          type: 'object',
          props: {
            cover_url: '',
            video_duration: '',
            video_watch_count: '',
          },
        },
        column: {
          type: 'object',
          props: {
            id: '',
            name: '',
            episode: '',
          },
        },
      },
    },
  },
}

export const getHomeOriginal = async (req?: any) => {
  const json = await fetch(
    getUrl(`/motor/pc/content/home_original?count=30`, 'motor.pc_content.api'),
    {
      req,
    }
  )

  if (0 !== json?.status) {
    throw Error(json?.prompts || json.message)
  }

  const data = json?.data || {}
  try {
    return req ? propsFilter(data, originFilterConfig) : data
  } catch (e) {
    if (req?.metrics) {
      req.metrics.emitCounter('props.filter.fail', 1, undefined, {
        url: '/motor/pc/content/home_original',
      })
    }
    return data
  }
}

export enum PGC_CAGETORY_SROUCE {
  VIDEO = 1,
  VIDEO_HOME = 2,
}

export interface IHomeVideoData {
  unique_id_str: string
  video_info: ICommonVideoInfo
  user_info: ICommonUserInfo
  column: ICommonColumn
}

export const getHomeVideo = async (req?: any) => {
  const json = await fetch(
    getUrl(
      `/motor/pc/content/home_video?count=48&source=1`,
      'motor.pc_content.api'
    ),
    {
      req,
    }
  )

  if (0 !== json?.status) {
    throw Error(json?.prompts || json.message)
  }

  return json?.data
}

export const friendshipLinkData = async (req: any) => {
  try {
    const json = await fetch(
      getUrl('/cloud/api/invoke/get_pc_friendship_link'),
      { req }
    )
    return json || []
  } catch (err) {
    console.error(err)
    return []
  }
}

export interface ICommonContentRankItem {
  title: string
  count: number
  format_count: string
  group_id: string
  article_type: number
}

export const getPGCVideoRank = async (req?: any) => {
  try {
    const json = await fetch(
      getUrl(
        '/motor/pc/content/pgc_content_rank?rank_type=pgc_video_total_rank',
        'motor.pc_content.api'
      ),
      { req }
    )
    if (0 !== json?.status) {
      throw Error(json?.prompts || json.message)
    }
    return json?.data?.list
  } catch (e) {
    return []
  }
}

export const getPGCArticleRank = async (req?: any) => {
  try {
    const json = await fetch(
      getUrl(
        '/motor/pc/content/pgc_content_rank?rank_type=pgc_article_total_rank',
        'motor.pc_content.api'
      ),
      { req }
    )
    if (0 !== json?.status) {
      throw Error(json?.prompts || json.message)
    }
    return json?.data?.list
  } catch (e) {
    return []
  }
}

export const getPGCCategoryList = async (
  req: any,
  source: number = PGC_CAGETORY_SROUCE.VIDEO
) => {
  try {
    const json = await fetch(
      getUrl(
        `/motor/pc/content/all_category_list?source=${source}`,
        'motor.pc_content.api'
      ),
      { req }
    )
    if (0 !== json?.status) {
      throw Error(json?.prompts || json.message)
    }
    return json?.data
  } catch (e) {
    return []
  }
}

export interface ILiveRoomItem {
  type: string
  room_id: string
  popularity: number
  title: string
  cover_url: string
  user_id: string
  user_name: string
  avatar: string
  rtmp_pull_url: string
  user_verified: boolean
  city_name: string
  live_id: number
  web_rid: string
}
export const getLiveRoomList = async (
  req: any,
  cityName = '',
  selectedCityName = ''
) => {
  const json = await fetch(
    getUrl(
      `/motor/pc/common/home_live_rooms?${serializeObject({
        count: 48,
        city_name: cityName,
        selected_city_name: cityName,
      })}`,
      'motor.pc_common.api'
    ),
    { req }
  )
  if (0 !== json?.status) {
    throw Error(json?.prompts || json.message)
  }
  return json?.data?.live_list
}

export interface ILiveBrandItem {
  brand_id: string
  brand_name: string
  logo: string
}
export const getLiveTabsList = async (req: any) => {
  const json = await fetch(
    getUrl('/motor/pc/common/live_tabs', 'motor.pc_common.api'),
    { req }
  )
  if (0 !== json?.status) {
    throw Error(json?.prompts || json.message)
  }
  return json?.data
}

const filterConfig: IFilterConfig = {
  type: 'object',
  props: {
    head_article: {
      type: 'array',
      props: {
        gid_str: '',
        is_video: '',
        title: '',
        hot: '',
        article_type: '',
      },
    },
    content_article: {
      type: 'array',
      props: {
        gid_str: '',
        is_video: '',
        title: '',
        hot: '',
        article_type: '',
      },
    },
  },
}

export const getTodayNews = async (req?: any) => {
  try {
    const json = await fetch(
      getUrl('/motor/pc/common/new_rcm_articles', 'motor.pc_common.api'),
      {
        req,
      }
    )
    const data = json?.data || {}
    try {
      return propsFilter(data, filterConfig)
    } catch (e) {
      if (req?.metrics) {
        req.metrics.emitCounter('props.filter.fail', 1, undefined, {
          url: '/motor/pc/common/new_rcm_articles',
        })
      }
      return data
    }
  } catch (err) {
    console.error(err)
    return {}
  }
}

export const getFocusPic = async (req: any) => {
  try {
    const json = await fetch(
      getUrl('/motor/pc/common/home/focus_pic', 'motor.pc_common.api'),
      {
        req,
      }
    )
    return json?.data || []
  } catch (err) {
    return []
  }
}

export const getFeedData = async (req: any) => {
  const json = await fetch(
    getUrl(
      '/motor/stream_entrance/get_feed_pc/v47/?tt_from=enter_auto&category=motor_car_use&sub_category=&impression_info=%7B"page_id"%3A"page_car_series"%2C"sub_tab"%3A"motor_car_use"%2C"product_name"%3A"web"%7D&aid=1839&refer=1&channel=web&device_platform=web&web_id=5999794205218462&motor_feed_extra_params=%7B"new_feed"%3A%20true%2C%20"feed_type"%3A%201%7D&source=pc',
      'motor.stream.entrance'
    ),
    { req }
  )
  return json?.data
}

export const getAroundCityList = async (sh_city_name: string, req?: any) => {
  const json = await fetch(
    getUrl(
      `/motor/pc/sh/around_city_list?${serializeObject({
        sh_city_name,
      })}`,
      'motor.pc_sh.api'
    ),
    { req }
  )
  if (0 !== json?.status) {
    throw Error(json?.prompts || json.message)
  }
  return json?.data
}
export const getShSkuTab = async (sh_city_name: string, req?: any) => {
  const json = await fetch(
    getUrl(
      `/motor/pc/sh/sh_sku_tab?${serializeObject({
        sh_city_name,
      })}`,
      'motor.pc_sh.api'
    ),
    { req }
  )
  if (0 !== json?.status) {
    throw Error(json?.prompts || json.message)
  }
  return json?.data
}
