export interface IFilterConfig {
  type: 'array' | 'object'
  filter?(item: any, index: number): unknown // 过滤数组
  props: Record<
    string,
    '' | ((value: any, index?: number) => any) | IFilterConfig
  >
}

function propsFilter(data: any, config: IFilterConfig) {
  return (function fn(data, config, index?: number) {
    if (data === null || data === undefined) {
      return data
    }
    if (config.type === 'array') {
      return (config.filter ? data.filter(config.filter) : data).map(
        (item: any, index: number) =>
          fn(
            item,
            {
              type: 'object',
              props: config.props,
            },
            index
          )
      )
    }

    const result: any = {}

    Object.keys(config.props).forEach((key) => {
      const item = config.props[key]
      if (typeof item === 'object') {
        result[key] = fn(data[key], item)
      } else if (typeof item === 'function') {
        try {
          result[key] =
            data[key] === null || data[key] === undefined
              ? data[key]
              : item(data[key], index)
        } catch (e) {
          // noop
        }
      } else {
        result[key] = data[key]
      }
    })
    return result
  })(data, config)
}

export default propsFilter
